import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button, Drawer, Tooltip, makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Scrollbar from '../Scrollbar'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import ViewListIcon from '@material-ui/icons/ViewList';
import { IconContext } from 'react-icons'
import { BiArrowFromLeft } from 'react-icons/bi';
import { ImBooks } from 'react-icons/im';
import CastIcon from '@material-ui/icons/Cast'

const useStyles = makeStyles((theme) => ({
  sidebar: {
  },
  sidebarAvatar: {
    backgroundColor: theme.palette.primary.main
  },
  arrowIcon: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

const DashboardMiniSidebar = (props) => {
  const classes = useStyles();
  const { onMobileClose, openMobile, onOpenSidebar } = props
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  const content = (
    <Box
      className={classes.sidebar}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-between',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Button
            color="button"
            component={RouterLink}
            align="center"
            fullWidth
            to="/dashboard"
            sx={{ p: 1 }}
          >
            <Tooltip title="Videos" placement='right'>
              <HomeIcon fontSize="large" color="primary" />
            </Tooltip>
          </Button>
          <Button
            color="button"
            component={RouterLink}
            align="center"
            fullWidth
            to="/dashboard/channels"
            sx={{ p: 1 }}
          >
            <Tooltip title="Videos" placement='right'>
              <OndemandVideoOutlinedIcon fontSize="large" color="primary" />
            </Tooltip>
          </Button>
          <Tooltip title="Courses" placement='right'>
            <Button
              color="button"
              component={RouterLink}
              align="center"
              fullWidth
              to="/dashboard/lms/courses"
              sx={{ p: 1 }}
            >
              <ImBooks style={{ fontSize: "20pt", color: "#1CABAA" }} />
            </Button>
          </Tooltip>
          <Button
            color="button"
            component={RouterLink}
            align="center"
            fullWidth
            to="/dashboard/series"
            sx={{ p: 1 }}
          >
            <Tooltip title="Series" placement='right'>
              <ViewListIcon fontSize="large" color="primary" />
            </Tooltip>
          </Button>
          <Button
            color="button"
            component={RouterLink}
            align="center"
            fullWidth
            to="/dashboard/workshops"
            sx={{ p: 1 }}
          >
            <Tooltip title="Live Events" placement='right'>
              <CastIcon style={{ fontSize: "25pt", color: "#1CABAA" }} />
            </Tooltip>
          </Button>
          <Button
            color="button"
            component={RouterLink}
            align="center"
            fullWidth
            to="/dashboard/instructors"
            sx={{ p: 1 }}
          >
            <Tooltip title="Instructors" placement='right'>
              <PeopleIcon fontSize="large" color="primary" />
            </Tooltip>
          </Button>
          <Button
            color="button"
            component={RouterLink}
            align="center"
            fullWidth
            to="/dashboard/reports"
            sx={{ p: 1 }}
          >
            <Tooltip title="Reports" placement='right'>
              <AssessmentOutlinedIcon fontSize="large" color="primary" />
            </Tooltip>
          </Button>
        </Box>
      </Scrollbar>
      <Box sx={{ pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Button
          color="button"
          component={RouterLink}
          align="center"
          to="/dashboard/help"
        >
          <Tooltip title="Help" placement='right'>
            <HelpOutlineOutlinedIcon fontSize="large" color="primary" />
          </Tooltip>
        </Button>
        <Box className={classes.arrowIcon} sx={{ mt: 2 }}>
          <Button
            color="button"
            align="center"
            onClick={onOpenSidebar}
          >
            <Tooltip title="Open Sidebar" placement='right'>
              <IconContext.Provider value={{ size: '2em' }}>
                <BiArrowFromLeft />
              </IconContext.Provider>
            </Tooltip>
          </Button>
        </Box>
      </Box>
    </Box>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 75,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          height: 'calc(100% - 64px) !important',
          top: '64px !Important',
          width: 70,
        },
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  )
}

DashboardMiniSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default DashboardMiniSidebar
