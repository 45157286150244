const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
        'Content-Type': 'application/json',
        'x-analytics-source': 'web'
    }
    if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

    return headers
}

// *********** CREATE COURSE *********** //
export const createCourse = async (details) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/courses`, opts).then((data) => data.json())

    return response
}


// *********** FETCH ALL COURSES *********** //
export const fetchCourses = async (userId, course_instructor, publisher = false) => {

    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const params = new URLSearchParams()

    if (course_instructor) {
        params.append('course_instructor', course_instructor)
    }

    if (userId) {
        params.append('user', userId)
    }

    if (publisher) {
        params.append('publisher', publisher)
    }
    const response = await fetch(`${nodeURL}/courses?${params.toString()}`, opts).then((data) => data.json())

    return response
}

// *********** FETCH A COURSE (BY ID) *********** //
export const fetchCourse = async (id) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/courses/${id}`, opts).then((data) => data.json())

    return response
}

// *********** UPDATE A COURSE (BY ID) *********** //
export const updateCourse = async (id, details) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/courses/${id}`, opts).then((data) => data.json())

    return response
}

// *********** DELETE A COURSE (BY ID) *********** //
export const deleteCourse = async (id) => {
    const opts = {
        method: 'DELETE',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/courses/${id}`, opts).then((data) => data.json())

    return response
}

export const fetchCourseSortOrder = async (userId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/courses/sortorder/${userId}`, opts).then((data) => data.json())

    return response
}

export const fetchFilteredSortedCourses = async (publisherId, filterVals) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }
    const params = new URLSearchParams()
    if (filterVals) {
        Object.keys(filterVals).forEach(key => params.append(key, filterVals[key]))
    }
    const response = await fetch(`${nodeURL}/courses/bypublisher/${publisherId}?${params.toString()}`, opts).then((data) => data.json())
    return response
}

export const fetchSortedCourses = async (publisherId, filterVals) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }
    const params = new URLSearchParams()
    if (filterVals) {
        Object.keys(filterVals).forEach(key => params.append(key, filterVals[key]))
    }
    const response = await fetch(`${nodeURL}/courses/sorted/${publisherId}?${params.toString()}`, opts).then((data) => data.json())
    return response
}

export const fetchCourseWithDetails = async (courseId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }
    const response = await fetch(`${nodeURL}/courses/detailed/${courseId}`, opts).then((data) => data.json())
    return response
}

// ********** GET ALL THE SECTION OBJECTS IN A COURSE (BY COURSE ID) ********** //
export const fetchCourseSections = async (courseId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/courses/${courseId}/sections`, opts);
    const data = response.json();

    return data;
}

export const fetchCourseCompletionData = async (courseId, userId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/completed-lectures?course=${courseId}&user=${userId}`, opts);
    const data = response.json();

    return data;
}

export const fetchUserCourseCompletionData = async (userId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    let completedLectures = []
    let hasMore = true
    let page = 1
    while (hasMore) {
        const response = await fetch(`${nodeURL}/completed-lectures?user=${userId}&_limit=100&_start=${(page - 1) * 100}`, opts);
        const data = await response.json();
        if (data.length < 100) {
            hasMore = false
        }
        completedLectures = completedLectures.concat(data)
        page++
    }

    return completedLectures;
}

export const markLectureComplete = async (courseId, lectureId, userId) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({ course: courseId, lecture: lectureId, user: userId, timestamp: new Date() })
    }

    const response = await fetch(`${nodeURL}/completed-lectures`, opts);
    const data = response.json();

    return data;
}

/**
 * // allCourses includes inProgressCourses too
 * @typedef CoursesResponse
 * @property {[]} allCourses
 * @property {[]} inProgressCourses
 */

/**
 * @param {string} publisherId
 * @param {string} courseInstructorId
 * @returns {Promise<CoursesResponse>}
 */

export const fetchAllCoursesUser = async (publisherId, courseInstructorId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const params = new URLSearchParams()
    params.append('publisherId', publisherId)

    if (courseInstructorId) {
        params.append('course_instructor', courseInstructorId)
    }

    const response = await fetch(`${nodeURL}/courses/mine?${params.toString()}`, opts);
    const data = response.json();

    return data;
}

/**
 * @param {string} publisherId
 * @param {string} userId
 * @returns {Promise<[]>}
 */

export const fetchAllCoursesAdmin = async (publisherId, userId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/courses/all?publisherId=${publisherId}&userId=${userId}`, opts);
    const data = response.json();

    return data;
}

/**
 * @param {string} courseId
 * @param {Object} quizData 
 * @param {string} quizData.title
 * @param {string} quizData.description
 * @param {string[]} quizData.questions
 * @param {string} quizData.course_lecture
 */

export const createQuiz = async (courseId, quizData) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({ courseId, ...quizData })
    }

    const response = await fetch(`${nodeURL}/quizzes`, opts);

    if (!response.ok) {
        throw new Error(`Error creating quiz: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
}

/**
 * @param {string} quizId
 * @param {Object} quizData 
 * @param {string} quizData.title
 * @param {string} quizData.description
 * @param {string[]} quizData.questions
 * @param {string} quizData.courseLectureId
 */

export const updateQuiz = async (quizId, quizData) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify({ ...quizData })
    }

    const response = await fetch(`${nodeURL}/quizzes/${quizId}`, opts);
    const data = await response.json();
    return data;
}

export const deleteQuiz = async (quizId) => {
    const opts = {
        method: 'DELETE',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quizzes/${quizId}`, opts);
    const data = await response.json();
    return data;
}

export const fetchQuiz = async (quizId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quizzes/${quizId}`, opts);
    const data = await response.json();
    return data;
}


export const fetchQuizzes = async () => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quizzes`, opts);
    const data = await response.json();
    return data;
}

export const fetchQuizzesByCourseId = async (courseId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quizzes?courseId=${courseId}`, opts);
    const data = await response.json();
    return data;
}

/**
 * @typedef {Object} Answer
 * @property {string} answerText 
 * @property {boolean} isCorrect 
 */

/**
 * Creates a question.
 *
 * @param {string} text 
 * @param {string} type 
 * @param {string} quizId 
 * @param {Answer[]} answers 
 */
export const createQuizQuestion = async (quizId, text, type, answers) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({ quizId, text, type, answers })
    }

    const response = await fetch(`${nodeURL}/questions`, opts);
    const data = await response.json();
    return data;
}

export const updateQuizQuestion = async (questionId, questionData) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify({ ...questionData })
    }

    const response = await fetch(`${nodeURL}/questions/${questionId}`, opts);
    const data = await response.json();
    return data;
}

export const deleteQuizQuestion = async (questionId) => {
    const opts = {
        method: 'DELETE',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/questions/${questionId}`, opts);
    const data = await response.json();
    return data;
}


export const fetchQuizQuestions = async (quizId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/questions?quizId=${quizId}`, opts);
    const data = await response.json();
    return data;
}

export const fetchQuizQuestion = async (questionId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/questions/${questionId}`, opts);
    const data = await response.json();
    return data;
}

/**
 * @typedef {Object} QuizCompletion
 * @property {string} userId
 * @property {string} courseId
 * @property {number} score
 * @property {Object} record
 */

export const createQuizCompletion = async (quizCompletion) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(quizCompletion)
    }

    const response = await fetch(`${nodeURL}/quiz-completions`, opts);
    const data = await response.json();
    return data;
}

export const updateQuizCompletion = async (quizCompletionId, quizCompletion) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(quizCompletion)
    }

    const response = await fetch(`${nodeURL}/quiz-completions/${quizCompletionId}`, opts);
    const data = await response.json();
    return data;
}

export const fetchQuizCompletions = async (quizId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quiz-completions?quizId=${quizId}`, opts);
    const data = await response.json();
    return data;
}

export const fetchQuizCompletion = async (quizCompletionId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quiz-completions/${quizCompletionId}`, opts);
    const data = await response.json();
    return data;
}

export const fetchQuizCompletionsByUser = async (userId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quiz-completions?userId=${userId}`, opts);
    const data = await response.json();
    return data;
}


export const fetchQuizCompletionByUserAndQuiz = async (userId, quizId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quiz-completions?userId=${userId}&quizId=${quizId}&_sort=createdAt:desc`, opts);
    const data = await response.json();
    return data;
}

export const fetchQuizCompletionsByUserAndCourse = async (userId, courseId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quiz-completions?userId=${userId}&courseId=${courseId}`, opts);
    const data = await response.json();
    return data;
}

export const fetchQuizzesByLectureId = async (lectureId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/quizzes?course_lecture=${lectureId}`, opts);
    const data = await response.json();
    return data;
}

