import { useState, useEffect } from 'react';
import { fetchScheduledWorkshops, WorkshopScheduleDay } from '../../../actions/workshopActions';
import { Box, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import { WorkshopListView } from './list';
import banner from '../../../assets/workshop-banner.jpeg'
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles(() => ({
    page: {
        backgroundColor: 'background.paper',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '10rem',
    },
    header: {
        textTransform: 'uppercase',
        marginTop: '2rem',
        marginBottom: '2rem',
        textAlign: 'center',
        zIndex: 2,
        color: 'white'
    },
    banner: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'flex-start',
        width: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        zIndex: 1,
    },
    button: {
        textTransform: 'uppercase',
        padding: '0.5rem 1.5rem',
        marginTop: '2rem',
    },
}));

export default function Workshops() {
    const { user } = useAuth();
    const classes = useStyles()
    /**
     * @type {[WorkshopScheduleDay[], void]}
     */
    const [workshops, setWorkshops] = useState([]);
    const [filteredWorkshops, setFilteredWorkshops] = useState([]);
    const [loading, setLoading] = useState(true);
    // const defaultWorkshopCover = 'https://peliuploads.s3.us-east-2.amazonaws.com/workshop_banner_385d890fb3.jpeg';
    const [searchQuery, setSearchQuery] = useState('');
    const [weeksAhead, setWeeksAhead] = useState(0);

    const getWorkshops = async () => {
        setLoading(true);

        const startDate = new Date();
        startDate.setUTCDate(startDate.getUTCDate() + (weeksAhead * 7));
        startDate.setUTCHours(0, 0, 0, 0);
        try {
            const response = await fetchScheduledWorkshops(startDate);
            console.debug('response', response);
            setWorkshops(response);
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!user) return;
        getWorkshops();
    }, [user, weeksAhead])

    useEffect(() => {
        if (searchQuery) {
            const filteredWorkshops = workshops.map((group) => {
                const filtered = group.workshops.filter((workshop) => workshop.title.toLowerCase().includes(searchQuery.toLowerCase()) || workshop.host.toLowerCase().includes(searchQuery.toLowerCase()))
                return { ...group, workshops: filtered }
            })
            const filteredGroups = filteredWorkshops.filter((group) => group.workshops.length > 0)
            setFilteredWorkshops(filteredGroups)
        } else {
            setFilteredWorkshops(workshops)
        }
    }, [searchQuery, workshops])

    return (
        <Box className={classes.page}>
            <Box className={classes.banner}>
                <Box className={classes.overlay} />
                <Typography
                    variant="h2"
                    sx={{
                        textTransform: 'uppercase',
                        my: { xs: 6, sm: 10, md: 12, lg: 16, xl: 20 },
                        textAlign: 'center',
                        zIndex: 2,
                        color: 'white'
                    }}
                >
                    LIVE
                </Typography>
            </Box>
            <Box sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                {loading
                    ? <CircularProgress sx={{ mt: 5 }} />
                    : <WorkshopListView
                        workshops={filteredWorkshops}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        loading={loading}
                        weekForward={() => setWeeksAhead(weeksAhead + 1)}
                        weekBack={() => setWeeksAhead(weeksAhead - 1)}
                        weeksAhead={weeksAhead}
                    />
                }
            </Box>
        </Box >
    )
}