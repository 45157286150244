import { Container, makeStyles, Typography, Button, Grid, Box, Table, TableCell, TableRow, TableBody, Tooltip, Dialog, DialogContent } from '@material-ui/core'
import PublisherSiteLogo from './publisher-site/PublisherSiteLogo'
import { paymentsApi } from '../actions/paymentsApi'
import { useEffect, useState } from 'react';
import UpdatePaymentForm from './UpdatePaymentForm';
import { restoreSubscription } from '../actions/subscriptionsApi';
import { LoadingButton } from '@material-ui/lab';
import CreditScoreOutlinedIcon from '@material-ui/icons/CreditScoreOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import toast from 'react-hot-toast';
import useAuth from '../hooks/useAuth';
import YogaRenewLogin from '../pages/authentication/YogaRenewLogin';

const useStyles = makeStyles(theme => ({
    container: {
        background: "white",
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        zIndex: 1,
        borderRadius: '6px',
        padding: '30px',
        [theme.breakpoints.up('md')]: {
            minWidth: '20vw',
            maxWidth: '25vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '30vw',
            maxWidth: '40vw'
        },
        [theme.breakpoints.up('xs')]: {
            width: '80vw',
        },
    },
    bigIconButton: {
        alignContent: 'center',
        marginTop: '2rem',
        marginBottom: '2rem'
    },
    hiddenForm: {
        position: 'relative',
        height: 0,
        transform: 'translateX(-100%)',
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard,
        }),
    }
}))

const SubscriptionManageForm = ({ customerId, subscription, updateSubscription, cancelPress, closePress, restorePress, updatePayment }) => {
    const classes = useStyles()

    const { user } = useAuth()
    const [paymentMethod, setPaymentMethod] = useState()
    const [paymentMethods, setPaymentMethods] = useState(null)
    const [updatingPayment, setUpdatingPayment] = useState(updatePayment)
    const [loading, setLoading] = useState(false)
    const [loginOpen, setLoginOpen] = useState(false)

    const calculateNextPayment = () => {
        const discount = subscription.discount
        if (!discount || !discount.coupon) return subscription.plan.amount
        if (discount.coupon.percent_off) {
            return subscription.plan.amount - (subscription.plan.amount * discount.coupon.percent_off / 100)
        } else {
            return Math.max(0, subscription.plan.amount - discount.coupon.amount_off)
        }
    }

    const getPaymentMethod = async (pmId) => {
        if (!pmId || pmId === null) return;
        const paymentMethod = await paymentsApi.getPaymentMethodDetails(pmId);
        setPaymentMethod(paymentMethod);
    }

    const getAllPaymentMethods = async () => {
        const paymentMethods = await paymentsApi.getAllPaymentMethods(customerId)
        setPaymentMethods(paymentMethods.data)
    }

    const setDefaultPaymentMethod = async (paymentMethodId) => {
        const toastId = toast.loading('Updating default payment method...');

        try {
            await paymentsApi.updateDefaultPaymentMethod(paymentMethodId, customerId, subscription.id);
            await getPaymentMethod(paymentMethodId);

            toast.dismiss(toastId);
            toast.success('Default payment method updated');
        } catch (error) {
            toast.dismiss(toastId);
            toast.error(error.message || 'Failed to update payment method');
            console.error('Error updating payment method:', error);
        }
    };

    const removePaymentMethod = async (paymentMethodId) => {
        const toastId = toast.loading('Removing payment method...');

        try {
            await paymentsApi.removePaymentMethod(paymentMethodId)
            toast.dismiss(toastId);
            toast.success('Payment method removed');
            setPaymentMethods(paymentMethods.filter(method => method.id !== paymentMethodId))
        } catch {
            toast.dismiss(toastId);
            toast.error('Failed to remove payment method');
        }
    }

    const handleRestore = async () => {
        setLoading(true)
        await restoreSubscription(subscription.id, user.id)
        restorePress()
        setLoading(false)
        toast.success('Subscription Restored')
    }

    useEffect(() => {
        if (customerId) getAllPaymentMethods();
    }, [customerId])

    useEffect(() => {
        if (!user && updatePayment) {
            setLoginOpen(true)
        } else {
            setLoginOpen(false)
        }
    }, [updatePayment, user])

    useEffect(() => {
        if (!subscription) return
        getPaymentMethod(subscription.default_payment_method)
    }, [subscription])

    if (!user) return (
        <Dialog open={loginOpen} onClose={() => setLoginOpen(false)}>
            <YogaRenewLogin setOpen={setLoginOpen} />
        </Dialog>
    )

    if (!subscription) return null

    return (
        <Container className={classes.container} sx={{ overflow: 'hidden' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <PublisherSiteLogo maxWidth="75%" />
            </Box>
            {updatingPayment
                ? <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">Update Payment Method</Typography>
                        <UpdatePaymentForm
                            onSuccess={() => {
                                updateSubscription();
                                setUpdatingPayment(false);
                                getAllPaymentMethods();
                            }}
                            onCancel={() => setUpdatingPayment(false)}
                        />
                    </Grid>
                </Grid>
                : <Grid container spacing={2} mt={1} justifyContent={'center'}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>Subscription Management</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ width: '250px', ml: 'auto', mr: 'auto' }}>
                            <Typography variant="h6" sx={{ textAlign: 'center' }}>Plan Information</Typography>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            {subscription.cancel_at_period_end ? 'Expires:' : (subscription.ended_at) ? 'Expired' : 'Next Payment:'}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                !subscription.ended_at && subscription.current_period_end * 1000 >= Date.now() && (
                                                    new Date(subscription.current_period_end * 1000).toLocaleDateString()
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            Plan:
                                        </TableCell>
                                        <TableCell>
                                            {subscription.plan.nickname} {(subscription.cancel_at_period_end || subscription.ended_at) ? '(Cancelled)' : ''}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            Amount:
                                        </TableCell>
                                        <TableCell>
                                            {`$${(calculateNextPayment() / 100).toFixed(2)}`}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>Default Payment Method</Typography>
                        <Box sx={{ ml: 'auto', mr: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                            <Box sx={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
                                <Typography sx={{ mr: 1, alignSelf: 'left' }}>
                                    **** **** **** {paymentMethod ? paymentMethod.last_four : '****'}
                                </Typography>
                                <Box sx={{ alignSelf: 'center', mt: "6px" }}>
                                    {paymentMethod
                                        ? <img alt="" src={`/static/icons/payment-icons/flat-round/${paymentMethod.card_type}.svg`} height={22} />
                                        : null
                                    }
                                </Box>
                                <Tooltip title="Update"><EditOutlinedIcon variant={"contained"} sx={{ ml: 1, color: "#1CABAA", cursor: 'pointer' }} onClick={() => setUpdatingPayment(true)} /></Tooltip>
                            </Box>
                        </Box>
                        {paymentMethod && paymentMethods?.length > 1 &&
                            <>
                                <Typography variant="h6" sx={{ textAlign: 'left' }}>Other Payment Methods</Typography>
                                <Box sx={{ ml: 'auto', mr: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                                    {paymentMethods.filter(method => method.card.last4 !== paymentMethod.last_four).map((method) => {
                                        return (
                                            <Box key={paymentMethod.id} sx={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
                                                <Typography sx={{ mr: 1, alignSelf: 'center' }}>
                                                    **** **** **** {method.card.last4}
                                                </Typography>
                                                <Box sx={{ alignSelf: 'center', mt: "6px" }}>
                                                    <img alt="" src={`/static/icons/payment-icons/flat-round/${method.card.brand}.svg`} height={22} />
                                                </Box>
                                                <Tooltip title="Update"><EditOutlinedIcon variant={"contained"} sx={{ ml: 1, color: "#1CABAA", cursor: 'pointer' }} onClick={() => setUpdatingPayment(true)} /></Tooltip>
                                                <Tooltip title="Set as default"><CreditScoreOutlinedIcon variant={"contained"} sx={{ ml: 1, color: "#1CABAA", cursor: 'pointer' }} onClick={() => setDefaultPaymentMethod(method.id)} /></Tooltip>
                                                <Tooltip title="Remove"><DeleteOutlineOutlinedIcon variant={"contained"} sx={{ ml: 1, color: "#1CABAA", cursor: 'pointer' }} onClick={() => removePaymentMethod(method.id)} /></Tooltip>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </>
                        }
                        <Box sx={{ mt: 4 }}>
                            <Typography variant='subtitle2' textAlign={'left'}>
                                {subscription.status === 'trialing' ? `Your free trial ends on ${new Date(subscription.trial_end * 1000).toLocaleDateString()}` : null}
                            </Typography>
                            <Typography variant='subtitle2' color='error' textAlign={'center'}>
                                {subscription.cancel_at_period_end ? `Your subscription is cancelled and will expire on ${new Date(subscription.current_period_end * 1000).toLocaleDateString()}` : null}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', '& > button': { ml: 1, mr: 1, mt: 2, mb: 2 }, width: '400px', justifyContent: 'center' }}>
                            {subscription.cancel_at_period_end && Date.now() / 1000 < subscription.current_period_end
                                ? <LoadingButton
                                    variant="outlined"
                                    color="success"
                                    fullWidth
                                    onClick={handleRestore}
                                    loading={loading}
                                >
                                    Restore Subscription
                                </LoadingButton>
                                : subscription.ended_at
                                    ? <Button
                                        variant="outlined"
                                        color="error"
                                        fullWidth
                                        href='/yogarenew/subscriptions'
                                        disabled={loading}
                                    >
                                        Subscribe Again
                                    </Button>
                                    : <Button
                                        variant="outlined"
                                        color="error"
                                        fullWidth
                                        onClick={cancelPress}
                                        disabled={loading}
                                    >
                                        Cancel Subscription
                                    </Button>
                            }
                            <Button variant="contained" fullWidth onClick={closePress}>Close</Button>
                        </Box>
                    </Grid>
                </Grid>
            }

        </Container>
    )
}

export default SubscriptionManageForm