import Analytics from 'analytics'

const nodeURL = process.env.REACT_APP_STRAPI_URL

/**
 * @typedef {Object} VideoAnalyticsSession
 * @property {string} videoId
 * @property {string} userId
 * @property {number} lastUpdate
 * @property {number} watchTime
 * @property {number} seekTime
 * @property {number} previousTime
 * @property {boolean} playing
 * @property {number} lastUpdateWatchTime
 */

/**
 * @typedef {Object} WatchtimeAnalyticsPlugin
 * @property {(videoId: string, lectureContentId: string) => Promise<string>} videoLoad
 * @property {(sessionId: string, seekTime: number) => void} videoPlay
 * @property {(sessionId: string) => void} videoPause
 * @property {(sessionId: string) => void} videoEnd
 * @property {(sessionId: string, previousTime: number, newTime: number) => void} videoSeek
 * @property {(sessionId: string) => void} unloaded
 */

/**
 * Maps analytics session id to video analytics session data
 * @type {Map<string, VideoAnalyticsSession>}
 */
const videoAnalyticsSessions = new Map();
/**
 * Maps video id to analytics session id
 * @type {Map<string, string>}
 */
const videoAnalyticsSessionsVideoIdMap = new Map();

var busy = false;
class EventEmitter {
    constructor() {
        this.callbacks = {}
    }

    on(event, cb) {
        if (!this.callbacks[event]) this.callbacks[event] = [];
        this.callbacks[event].push(cb)
    }

    off(event) {
        if (this.callbacks[event]) {
            this.callbacks[event] = [];
        }
    }

    emit(event, data) {
        let cbs = this.callbacks[event]
        if (cbs) {
            cbs.forEach(cb => cb(data))
        }
    }
}

const emitter = new EventEmitter();

const headers = () => {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
        'Content-Type': 'application/json',
        'x-analytics-source': 'web'
    }
    if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
    return headers;
}

/**
 * @typedef {{
 *  event: 'video_impression' | 'clickthrough' | 'error' | 'page_view' | 'session_start' | 'video_start' | 'video_end' | 'video_pause' | 'video_play' | 'video_seek' | 'video_search' | 'interaction'
 *  sessionId: string
 *  userId?: string
 *  targetId?: string
 *  tag?: string
 *  referrer?: string
 *  timestamp: Date
 *  pageUrl: string
 * }} AnalyticsEvent
 */

const analytics = Analytics({
    app: 'peli',
    plugins: [{
        name: 'watchtime',
        methods: {
            on: (e, cb) => emitter.on(e, cb),
            off: (e) => emitter.off(e),
            videoLoad: async (videoId, lectureContentId) => {
                const existingSessionId = videoAnalyticsSessionsVideoIdMap.get(videoId);
                if (existingSessionId) {
                    console.debug("analytics.watchtime.videoLoad existing session id", existingSessionId);
                    return existingSessionId;
                }

                const sessionId = await getWatchtimeAnalyticsId(videoId, lectureContentId);
                console.debug("analytics.watchtime.videoLoad new session id", sessionId);
                if (!sessionId) {
                    console.warn("analytics.watchtime.videoLoad failed to create session id - cannot track video watchtime")
                    return;
                }

                videoAnalyticsSessionsVideoIdMap.set(videoId, sessionId);
                videoAnalyticsSessions.set(sessionId, {
                    videoId,
                    lastUpdate: Date.now(),
                    watchTime: 0,
                    seekTime: 0,
                    playing: false,
                    lastUpdateWatchTime: 0
                });

                return sessionId;
            },
            videoPlay: (analyticsId, currentTime) => {
                const session = videoAnalyticsSessions.get(analyticsId);
                if (!session || currentTime === undefined) {
                    console.warn("analytics.watchtime.videoPlay invalid session id or time", analyticsId, currentTime);
                    return;
                }

                if (!session.playing) {
                    session.lastUpdate = new Date().getTime()
                    session.playing = true
                }
                session.seekTime = currentTime
                if (session.playing) {
                    update(analyticsId, session);
                }
            },
            videoSeek: (analyticsId, previousTime, newTime) => {
                const session = videoAnalyticsSessions.get(analyticsId);
                if (!session || previousTime === undefined || newTime === undefined) {
                    console.warn("analytics.watchtime.videoSeek invalid session id or time", analyticsId, previousTime, newTime);
                    return;
                }

                console.debug("analytics.watchtime.videoSeek", analyticsId, previousTime, newTime);

                session.seekTime = newTime;
                session.previousTime = previousTime;
                update(analyticsId, session, true);
                session.playing = false;
            },
            videoPause: (analyticsId, currentTime) => {
                const session = videoAnalyticsSessions.get(analyticsId);
                if (!session || currentTime === undefined) {
                    console.warn("analytics.watchtime.videoPause invalid session id or time", analyticsId, currentTime);
                    return;
                }

                if (session.playing) {
                    session.seekTime = currentTime
                    update(analyticsId, session, true)
                    session.playing = false
                }
            },
            videoEnd: (analyticsId, currentTime) => {
                const session = videoAnalyticsSessions.get(analyticsId);
                if (!session || currentTime === undefined) {
                    console.warn("analytics.watchtime.videoEnd invalid session id or time", analyticsId, currentTime);
                    return;
                }

                session.seekTime = currentTime
                update(analyticsId, session, true)
                session.playing = false
            },
            unloaded: (analyticsId) => {
                const session = videoAnalyticsSessions.get(analyticsId);
                if (!session) {
                    console.warn("analytics.watchtime.unloaded invalid session id", analyticsId);
                    return;
                }

                update(analyticsId, session, true);
                session.watchTime = 0
            }
        }
    },
    peliAnalyticsPlugin({})]
});

/**
 * 
 * @param {string} analyticsId 
 * @param {VideoAnalyticsSession} session
 * @param {boolean} commit 
 */
const update = (analyticsId, session, commit = false) => {
    const now = new Date().getTime()

    if (session.playing) {
        session.watchTime += now - (session.lastUpdate ? session.lastUpdate : now)
    }

    session.lastUpdate = now

    if (session.watchTime - session.lastUpdateWatchTime >= 10000 || session.previousTime !== undefined && !busy) {
        busy = true;
        updateWatchtimeAnalytics(
            analyticsId,
            Math.floor(session.watchTime / 1000),
            Math.floor(session.seekTime),
            Math.floor(session.previousTime),
            commit
        )
            .then(() => {
                delete session.previousTime
            })
            .finally(() => {
                session.lastUpdateWatchTime = session.watchTime;
                busy = false;
            })
    }
}

/**
 * 
 * @param {string} analyticsId 
 * @param {number} watchtime 
 * @param {number} seektime 
 * @param {number} previousTime 
 * @param {boolean} commit 
 * @returns {Promise<void>}
 */
const updateWatchtimeAnalytics = async (analyticsId, watchtime, seektime, previousTime, commit = false) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify({
            seek_time: seektime,
            watch_time: watchtime,
            previous_time: previousTime,
            commit: commit
        }),
        credentials: 'include'
    }
    try {
        const result = await fetch(`${nodeURL}/video-views/${analyticsId}`, opts);
        if (result.status === 200) {
            const data = await result.json();
            if (data && data.pointsAwarded) {
                emitter.emit('points-awarded', data.pointsAwarded);
            }
        }
    } catch (err) {
        console.error("Error updating watchtime", err)
    }
}

/**
 * @param {string} videoId
 * @param {string | undefined} lectureContentId
 * @returns {Promise<string | null>}
 */
const getWatchtimeAnalyticsId = async (videoId, lectureContentId) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({ video: videoId, lecture_content: lectureContentId }),
        credentials: 'include'
    }

    try {
        const session = await fetch(`${nodeURL}/video-views`, opts).then((data) => data.json())
        return session.id
    } catch (err) {
        console.error("Error creating watchtime", err)
    }

    return null
}

const flushInterval = 10000;
const flushIntervalRef = setInterval(() => {
    analytics.plugins['peli-analytics'].flush();
}, flushInterval);

window.addEventListener('beforeunload', () => {
    clearInterval(flushIntervalRef);
    analytics.plugins['peli-analytics'].flush();
});

function peliAnalyticsPlugin(config) {
    /** @type {AnalyticsEvent[]} */
    const analyticsEventQueue = [];

    const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL

    const getSession = async () => {
        const anonId = localStorage.getItem('__anon_id')?.replace(/"/g, '');
        // TODO: will this be set by the time this is called?
        let userId = null;

        const userData = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
        if (userData) userId = userData._id;

        const opts = {
            method: 'GET',
            headers: headers(),
            credentials: 'include'
        }

        const url = new URL('/session', analyticsUrl);
        if (anonId) url.searchParams.append('anonymousId', anonId);
        if (userId) url.searchParams.append('userId', userId);

        return await fetch(url, opts).then(data => data.json())
    }

    return {
        name: 'peli-analytics',
        config: Object.assign({}, config),
        initialize: ({ config }) => {
            getSession()
                .then(data => analytics.plugins['peli-analytics'].sessionId = data.id)
                .catch(err => console.error("Error getting session", err));
        },
        page: ({ payload }) => {
            console.log('Peli analytics plugin page tracked', payload)
        },
        track: ({ payload }) => {
            analyticsEventQueue.push(payload);
        },
        identify: ({ payload }) => {
            console.log('Peli analytics plugin user identified', payload)
        },
        loaded: () => {
            return true
        },
        methods: {
            flush: async () => {
                const events = analyticsEventQueue.splice(0, analyticsEventQueue.length);
                if (events.length === 0) return;

                const mapped = events.map(event => {
                    const base = {
                        name: event.event,
                        timestamp: new Date(event.meta.ts)
                    }

                    Object.assign(base, event.properties);

                    return base;
                });

                const opts = {
                    method: 'POST',
                    headers: headers(),
                    body: JSON.stringify({ events: mapped }),
                    credentials: 'include'
                }

                try {
                    await fetch(`${analyticsUrl}/events`, opts);
                } catch (err) {
                    console.error("Error sending analytics", err)
                }
            }
        },
        events: [
            'video_impression',
            'clickthrough',
            'error',
            'page_view',
            'session_start',
            'video_start',
            'video_end',
            'video_pause',
            'video_play',
            'video_seek',
            'video_search',
            'interaction'
        ]
    }
}

export default analytics;
