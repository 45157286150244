import { Card, CardHeader, Divider, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography, makeStyles, Container, IconButton, Button } from '@material-ui/core';
import { useEffect, useState, useCallback } from 'react';
import Label from '../../components/Label';
import { invoiceApi } from '../../actions/invoiceApi';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    container: {
        background: "white",
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        minWidth: '20vw',
        zIndex: 1,
        borderRadius: '6px',
        padding: '30px',
        [theme.breakpoints.up('md')]: {
            minWidth: '50vw',
            maxWidth: '70vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '30vw',
            maxWidth: '40vw'
        },
        [theme.breakpoints.up('xs')]: {
            width: '98vw',
            margin: 0
        },
        transition: theme.transitions.create('all', {
            duration: 500,
        })
    },
    button: {
        marginTop: '1rem'
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
}))


const TransactionPopOver = ({ customer, setTransactionModalOpen }) => {

    /**
     * @type {[Array, Function]}
     */
    const [transactions, setTransactions] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [perPage, setPerPage] = useState(5)
    const [page, setPage] = useState(0)
    const [isIAPHubTransactions, setIsIAPHubTransactions] = useState(false)
    const classes = useStyles()
    const displayedTransactions = customer.stripecustomerid ? transactions.slice(page * perPage, (page + 1) * perPage) : transactions

    const getTransactions = useCallback(async (abortSignal, transactions) => {
        try {
            if (customer.stripecustomerid) {
                const data = await invoiceApi.getInvoices({
                    limit: perPage,
                    mapStrapiUserData: false,
                    expand: ['data.charge'],
                    starting_after: transactions.length === 0 ? undefined : transactions.at(-1).id,
                    customerId: customer.stripecustomerid
                }, abortSignal)
                if (data?.data) {
                    setTransactions(prevTransactions => [...prevTransactions, ...data.data])
                    setHasMore(data.has_more)
                    return
                }
            }

            if (customer.iaphubuserid) {
                const data = await invoiceApi.getIAPHUBInvoices(customer.iaphubuserid, perPage, page + 1)
                console.log('data', data)
                if (data?.list) setTransactions(data.list)
                setHasMore(data?.hasNextPage)
                setIsIAPHubTransactions(true)
            }
        } catch (err) {
            console.error(err)
            setTransactions([])
        }
    }, [perPage, customer])

    useEffect(() => {
        const abort = new AbortController()
        getTransactions(abort.signal, transactions)

        return () => abort.abort()
    }, [perPage, customer])

    useEffect(() => {
        setPage(0)
    }, [perPage])

    const getStatusLabel = (transaction) => {
        const paymentStatus = (transaction.charge?.refunded || transaction.isRefunded) ? 'refunded' : transaction.price ? 'paid' : transaction.status
        const map = {
            void: {
                color: 'error',
                text: 'Void',
            },
            paid: {
                color: 'success',
                text: 'Paid',
            },
            open: {
                color: 'warning',
                text: 'Failed',
            },
            uncollectible: {
                color: 'error',
                text: 'Uncollectible',
            },
            draft: {
                color: 'info',
                text: 'Draft',
            },
            refunded: {
                color: 'warning',
                text: 'Refunded'
            }
        }

        const { text, color } = map[paymentStatus]

        return <Label color={color}>{text}</Label>
    }

    if (!customer.stripecustomerid && !customer.iaphubuserid) return null

    return (
        <Container className={classes.container}>
            <IconButton
                className={classes.closeButton}
                onClick={() => { setTransactionModalOpen(false) }}
                disableRipple
                sx={{
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <CloseIcon />
            </IconButton>
            <Card style={{ marginTop: 14 }}>
                <CardHeader title="Transactions" />
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography color="textPrimary" variant="subtitle2">
                                    Transaction ID
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="textPrimary" variant="subtitle2">
                                    Amount
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="textPrimary" variant="subtitle2">
                                    {!isIAPHubTransactions ? 'Date' : 'Expiration Date'}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography color="textPrimary" variant="subtitle2">
                                    Status
                                </Typography>
                            </TableCell>
                            {!isIAPHubTransactions && <TableCell>
                                <Typography color="textPrimary" variant="subtitle2">
                                    Invoice
                                </Typography>
                            </TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            displayedTransactions.map(transaction => (
                                <TableRow hover key={transaction.id}>
                                    <TableCell>
                                        <Typography color="textSecondary" variant="body2">
                                            {transaction.number || transaction.orderId}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary" variant="body2">
                                            {transaction.price ? Intl.NumberFormat('en-US', { style: 'currency', currency: transaction.currency }).format(transaction.price) : Intl.NumberFormat('en-US', { style: 'currency', currency: transaction.currency }).format(transaction.amount_due / 100)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary" variant="body2">
                                            {transaction.created ? Intl.DateTimeFormat("en-US", {}).format(new Date(transaction.created * 1000)) : transaction.expirationDate ? new Date((transaction.expirationDate)).toLocaleDateString() : '---'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary" variant="body2">
                                            {getStatusLabel(transaction)}
                                        </Typography>
                                    </TableCell>
                                    {transaction.hosted_invoice_url && <TableCell>
                                        <Typography color="textSecondary" variant="body2">
                                            <Button href={transaction.hosted_invoice_url} target="_blank" variant="text">View Invoice</Button>
                                        </Typography>
                                    </TableCell>}
                                </TableRow>
                            ))
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                sx={{ display: page === 0 && !hasMore ? 'none' : undefined }}
                                page={page}
                                rowsPerPage={perPage}
                                rowsPerPageOptions={[5, 10, 25]}
                                onPageChange={(e, newPage) => {
                                    if (newPage > page) {
                                        if (hasMore) {
                                            setPage(newPage)
                                            getTransactions(new AbortController().signal, transactions)
                                        }
                                    } else {
                                        setPage(newPage)
                                        if (newPage === 0) {
                                            setTransactions([])
                                            getTransactions(new AbortController().signal, [])
                                        }
                                    }
                                }}
                                onRowsPerPageChange={(e) => {
                                    setTransactions([])
                                    setPerPage(e.target.value)
                                }}
                                count={-1}
                                nextIconButtonProps={{ disabled: !hasMore }}
                                backIconButtonProps={{ disabled: page === 0 }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </Card>
        </Container>
    );
};

export default TransactionPopOver;
