import { Navigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const SubscriberGuard = ({ children }) => {
	const { user } = useAuth()
	const currentRoute = window.location.pathname

	// if (user && user.subscriptionExpired && user.subtype !== 'publisher') {
	// 	const params = new URLSearchParams(window.location.search)
	// 	const priceId = params.get('priceId')
	// 	if (!priceId) {
	// 		console.log('SubscriberGuard: navigating to /yogarenew/subscriptions')
	// 		return <Navigate to='/yogarenew/subscriptions' />
	// 	}
	// }

	// if (user && !user.subscriptionExpired && currentRoute.includes('subscriptions')) {
	// 	return <Navigate to='/yogarenew' />
	// }

	return <>{children}</>
}

export default SubscriberGuard
