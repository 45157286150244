import { Modal, Box, Typography, Button, IconButton, Container, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
    container: {
        background: "white",
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        minWidth: '20vw',
        zIndex: 1,
        borderRadius: '6px',
        padding: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            minWidth: '50vw',
            maxWidth: '70vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '30vw',
            maxWidth: '40vw'
        },
        [theme.breakpoints.up('xs')]: {
            width: '98vw',
            margin: 0
        },
        transition: theme.transitions.create('all', {
            duration: 500,
        })
    },
    button: {
        marginTop: '1rem'
    },
    closeButton: {
        position: 'absolute',
        top: '5px',
        right: '10px',
    },
}));


export default function ReferralPopover({ open, setOpen, referralCode }) {
    const classes = useStyles();

    const handleCopyReferralLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/yogarenew/subscriptions?referralCode=${referralCode}`);
        toast.success('Referral link copied to clipboard');
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)} className={classes.modal}>
            <Container className={classes.container}>
                <IconButton
                    className={classes.closeButton}
                    onClick={() => { setOpen(false) }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5">Refer a Friend</Typography>
                <Typography variant="body1">Share the following referral code or link with your friends and earn rewards!</Typography>
                {referralCode ? (
                    <>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            my: 2,
                            width: '70%',
                            border: '1px solid #e0e0e0',
                            borderRadius: '6px',
                            padding: '1rem',
                            margin: '1rem auto'
                        }}>
                            <Typography variant="h3">{referralCode}</Typography>
                        </Box>
                        <Button variant="contained" color="primary" onClick={handleCopyReferralLink}>Copy Referral Link</Button>
                    </>
                ) : (
                    <Box sx={{ mt: 3 }}>
                        <CircularProgress />
                    </Box>
                )}
            </Container>
        </Modal>
    );
}



