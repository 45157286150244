import { PAYMENTS_URL } from '../constants'

class PaymentsApi {
	headers = () => {
		const accessToken = localStorage.getItem('accessToken')
		const headers = {
			'Content-Type': 'application/json',
			'x-analytics-source': 'web'
		}
		if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

		return headers
	}

	async getPaymentMethodDetails(pmId) {
		if (!pmId) return { error: true, msg: "No payment method ID provided" };

		try {
			const opts = {
				method: 'GET',
				headers: this.headers()
			}

			const resp = await fetch(`${PAYMENTS_URL}/payments/customers/paymentmethod/${pmId}`, opts)
			const paymentMethod = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(paymentMethod.msg)
			}
			return paymentMethod
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	async getAllPaymentMethods(customerId) {
		try {
			const opts = {
				method: 'GET',
				headers: this.headers()
			}

			const resp = await fetch(`${PAYMENTS_URL}/payments/customers/paymentmethods/${customerId}`, opts)
			const paymentMethods = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(paymentMethods.msg)
			}
			return paymentMethods
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	async updateDefaultPaymentMethod(paymentMethodId, customerId, subscriptionId) {
		try {
			const opts = {
				method: 'PUT',
				headers: this.headers(),
				body: JSON.stringify({ paymentMethodId, customerId, subscriptionId }),
			}

			const resp = await fetch(`${PAYMENTS_URL}/payments/customers/paymentmethods/default`, opts)
			const paymentMethod = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(paymentMethod.msg)
			}
			return paymentMethod
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	async removePaymentMethod(paymentMethodId) {
		try {
			const opts = {
				method: 'DELETE',
				headers: this.headers(),
			}
			const resp = await fetch(`${PAYMENTS_URL}/payments/customers/paymentmethod/${paymentMethodId}`, opts)
			const paymentMethod = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(paymentMethod.msg)
			}
			return paymentMethod
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	async getPrice(priceId) {
		try {
			const opts = {
				method: 'GET',
				headers: this.headers()
			}

			const resp = await fetch(`${PAYMENTS_URL}/payments/prices/${priceId}`, opts)
			const price = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(price.msg)
			}
			return price
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	async getPromo(promoId) {
		try {
			const opts = {
				method: 'GET',
				headers: this.headers()
			}

			const resp = await fetch(`${PAYMENTS_URL}/payments/promos/${promoId}`, opts)
			const promo = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(promo.msg)
			}
			return promo
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	/**
	 * @typedef CreateCustomerRequest
	 * @property {string} email - Customer email
	 * @property {string} name - Customer name
	 * @property {string} phone - Customer phone
	 * @property {object} address - Customer address
	 * @property {string} address.line1 - Customer address line 1
	 * @property {string} address.line2 - Customer address line 2
	 * @property {string} address.city - Customer address city
	 * @property {string} address.state - Customer address state
	 * @property {string} address.postal_code - Customer address postal code
	 * @property {string} address.country - Customer address country
	 * @property {string} payment_method - Customer payment method id
	 * @property {object} metadata - Customer metadata
	 * @property {string} metadata.[string] - Customer user id
	 */
	/**
	 * 
	 * @param {CreateCustomerRequest} data 
	 * @returns 
	 */
	async createCustomer(data) {
		try {
			const opts = {
				method: 'POST',
				headers: this.headers(),
				body: JSON.stringify(data),
			}

			const response = await fetch(`${PAYMENTS_URL}/payments/customers/create`, opts)
			const responseJson = await response.json()

			if (!response || response.status === 400) {
				throw new Error(responseJson.msg)
			}
			return responseJson
		} catch (err) {
			console.error('[Payments Api]: ', err)
			return { error: true, msg: err.message }
		}
	}

	async updateCustomer(body) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = {
					method: 'PUT',
					headers: this.headers(),
					body: JSON.stringify(body),
				}

				const response = await fetch(`${PAYMENTS_URL}/payments/customers/update`, opts)
				const responseJson = await response.json()

				if (!response || response.status === 400) {
					throw new Error(responseJson.msg)
				}

				resolve(responseJson)
			} catch (err) {
				console.error('[Payments Api]: ', err)
				resolve({ error: true, msg: err.message })
			}
		})
	}

	async createSetupIntent() {
		try {
			const opts = {
				method: 'GET',
				headers: this.headers()
			}

			const resp = await fetch(`${PAYMENTS_URL}/payments/checkout/setup-intent`, opts)
			const responseJson = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(responseJson.msg)
			}
			return responseJson
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	async setupCheckout({ setupIntentId, clientSecret, email, firstName, lastName, referralCode }) {
		try {
			const opts = {
				method: 'POST',
				headers: this.headers(),
				body: JSON.stringify({ setupIntentId, clientSecret, email, firstName, lastName, referralCode }),
			}

			const resp = await fetch(`${PAYMENTS_URL}/payments/checkout/setup-checkout`, opts)
			const responseJson = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(responseJson.msg)
			}
			return responseJson
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	async completeCheckout({ setupIntentId, clientSecret, price, promo }) {
		try {
			const opts = {
				method: 'POST',
				headers: this.headers(),
				body: JSON.stringify({ setupIntentId, clientSecret, price, promo }),
			}

			const resp = await fetch(`${PAYMENTS_URL}/payments/checkout/complete-checkout`, opts)
			const responseJson = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(responseJson.msg)
			}
			return responseJson
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	async checkTrialEligibility({ customerId, priceId }) {
		try {
			const opts = {
				method: 'GET',
				headers: this.headers(),
			}

			const resp = await fetch(
				`${PAYMENTS_URL}/payments/checkout/trial-eligibility?customerId=${customerId}&priceId=${priceId}`,
				opts
			)

			const responseJson = await resp.json()
			if (!resp || resp.status > 299) {
				throw new Error(responseJson.msg)
			}
			return responseJson
		} catch (e) {
			console.error('[Payments Api]: ', e)
			return { error: true, msg: e.message }
		}
	}

	async setUpFreeTrial({ setupIntentData, email, firstName, lastName }) {
		const opts = {
			method: 'POST',
			headers: this.headers(),
			body: JSON.stringify({ setupIntentData, email, firstName, lastName }),
		}

		const resp = await fetch(`${PAYMENTS_URL}/payments/checkout/setup-free-trial`, opts)
		const responseJson = await resp.json()
		if (!resp || resp.status !== 200) {
			throw new Error('Error setting up free trial')
		}

		return responseJson
	}
}

export const paymentsApi = new PaymentsApi()
