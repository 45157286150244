import React, { useEffect, useState } from 'react';
import { TextField, Button, LinearProgress, Grid, Typography, Checkbox, ImageListItem, FormGroup, FormControlLabel, Box, Select, MenuItem } from '@mui/material';
import toast from 'react-hot-toast';
import { createAttachment } from '../../../actions/videoActions';
import FileUpload from '../../../components/FileUpload';
import { createWorkshop, authorizeZoom, getZoomAccessToken, checkZoomAuth, revokeZoomAuth } from '../../../actions/workshopActions';
import ImageCropper from '../../../components/ImageCropper';

const WorkshopForm = ({ setShowAddWorkshopDialog, getWorkshops }) => {
    const [workshop, setWorkshop] = useState({
        title: '',
        duration: 60,
        host: '',
        date: new Date(),
        cover: null,
        information: '',
        autoRecord: true,
        sendRecording: true,
        recurring: false,
        recurrence: 'daily',
        repeatEvery: 1,
        endDateType: 'none',
        endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        endDateOccurrences: 2,
    });
    const [isUploading, setIsUploading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newCoverImage, setNewCoverImage] = useState(null);
    const [isZoomAuthorized, setIsZoomAuthorized] = useState(false);
    const [src, setSrc] = useState(null);
    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get('code')

    const handleChange = (event) => {
        const { name, value } = event.target;
        setWorkshop(prev => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setWorkshop(prev => ({ ...prev, [name]: checked }));
    };

    const handleDateChange = (event) => {
        const { name, value } = event.target;
        setWorkshop(prev => ({ ...prev, [name]: new Date(value) }));
        if (name === 'date' && new Date(value).getTime() + 24 * 60 * 60 * 1000 > new Date(workshop.endDate).getTime()) {
            setWorkshop(prev => ({ ...prev, endDate: new Date(new Date(value).getTime() + 24 * 60 * 60 * 1000) }));
        }
    };

    const handleNumberChange = (event, min, max = null) => {
        const { name, value } = event.target;
        if (isNaN(parseInt(value))) {
            return setWorkshop(prev => ({ ...prev, [name]: min }));
        }
        const number = parseInt(value);
        let numberToSet = number;
        if (number < min) {
            numberToSet = min;
        }
        if (max && number > max) {
            numberToSet = max;
        }
        setWorkshop(prev => ({ ...prev, [name]: numberToSet }));
    }

    const handleUploadPhoto = async ([attachment]) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            setSrc(fileReader.result);
        };
        fileReader.readAsDataURL(attachment);
    };

    const handleCropComplete = async (croppedImageUrl) => {
        setIsUploading(true);
        try {
            const response = await fetch(croppedImageUrl);
            const blob = await response.blob();
            const croppedFile = new File([blob], 'croppedImage.jpeg', { type: 'image/jpeg' });

            const data = new FormData();
            data.append('files', croppedFile);
            const loading = toast.loading('Uploading cover image...');
            const uploadResponse = await createAttachment(data);
            if (uploadResponse) {
                toast.dismiss(loading);
                toast.success('Cover image uploaded!');
                setWorkshop((prev) => ({ ...prev, cover: uploadResponse[0] }));
                setNewCoverImage(uploadResponse[0]);
            }
        } catch (error) {
            console.error(error, 'Cover image upload error');
            toast.error('Failed to upload cover.');
        } finally {
            setIsUploading(false);
            setSrc(null); // Reset the source to close the cropper
        }
    };

    const handleZoomAuthorize = async () => {
        const redirectUrl = window.location.href;
        try {
            const response = await authorizeZoom(redirectUrl);
            if (response.url) {
                window.location.href = response.url;
            }
        } catch (error) {
            console.error(error, 'Zoom authorize error');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const dateObject = new Date(workshop.date);
        const utcDate = dateObject.toISOString();

        if (dateObject < new Date()) {
            return toast.error('Live event cannot be in the past');
        }

        const submissionValues = { ...workshop, date: utcDate };

        for (const key in submissionValues) {
            if (submissionValues[key] === '' || submissionValues[key] === null) {
                return toast.error(`Missing ${key} field`);
            }
        }

        try {
            setIsSubmitting(true);
            const response = await createWorkshop(submissionValues);
            if (response.id) {
                setWorkshop({
                    title: '',
                    duration: '',
                    host: '',
                    date: new Date(),
                    cover: null,
                    information: '',
                    autoRecord: true,
                    sendRecording: true,
                    recurring: false,
                    recurrence: 'daily',
                    repeatEvery: 1,
                    endDateType: 'none',
                    endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                    endDateOccurrences: 2,
                });
                setNewCoverImage(null);
                if (submissionValues.recurring) {
                    toast.success('Workshops added successfully!');
                    await getWorkshops();
                } else {
                    toast.success('Workshop added successfully!');
                    await getWorkshops();
                }
            } else {
                const errorMessage = `Failed to add live event: ${response.message}`;
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error('Submission error', error);
            toast.error('Failed to add live event.');
        } finally {
            setIsSubmitting(false);
            setShowAddWorkshopDialog(false);
        }
    };

    const zoomCallback = async (code, redirect_url) => {
        try {
            const response = await getZoomAccessToken(code, redirect_url);

            if (response.authorized) {
                localStorage.setItem('zoomAccessToken', response.access_token);
                localStorage.setItem('zoomRefreshToken', response.refresh_token);
                setIsZoomAuthorized(true);
                window.history.pushState({}, document.title, window.location.pathname);
            } else {
                setIsZoomAuthorized(false);
                toast.error('Failed to authorize Zoom account');
                console.error(response);
            }
        } catch (error) {
            console.error(error, 'Zoom access token error');
        }
    }

    const handleRevokeZoom = async () => {
        try {
            const response = await revokeZoomAuth();
            if (response.disconnected) {
                localStorage.removeItem('zoomAccessToken');
                localStorage.removeItem('zoomRefreshToken');
                handleZoomAuthorize();
            } else {
                toast.error('Failed to disconnect Zoom account');
            }
        } catch (error) {
            console.error(error, 'Revoke Zoom error');
            toast.error('Failed to disconnect Zoom account');
        }
    }

    const formatDateTimeLocal = (date) => {
        let dateToFormat = date;
        if (!dateToFormat || isNaN(new Date(dateToFormat).getTime())) {
            dateToFormat = new Date();
        }

        const year = dateToFormat.getFullYear();
        const month = String(dateToFormat.getMonth() + 1).padStart(2, '0');
        const day = String(dateToFormat.getDate()).padStart(2, '0');
        const hours = String(dateToFormat.getHours()).padStart(2, '0');
        const minutes = String(dateToFormat.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    useEffect(() => {
        const redirect_url = window.location.href.split('?')[0];
        if (code) {
            zoomCallback(code, redirect_url)
        }
    }, [code]);

    useEffect(() => {
        const checkZoomAuthStatus = async () => {
            const response = await checkZoomAuth();
            if (response.authorized) {
                localStorage.setItem('zoomAccessToken', response.access_token);
                setIsZoomAuthorized(true);
            } else {
                setIsZoomAuthorized(false);
            }
        }
        checkZoomAuthStatus();
    }, []);

    // console.log('workshop', workshop);

    return (
        !isZoomAuthorized ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: -2 }}>
                <Button onClick={handleZoomAuthorize} variant='contained' sx={{ my: 3, mr: 3 }}>
                    Connect Zoom to Add Live Events
                </Button>
                <Button onClick={() => setShowAddWorkshopDialog(false)} color="primary">
                    Close
                </Button>
            </Box>
        ) : (
            <>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>Add New Workshop</Typography>
                        <Button onClick={() => setShowAddWorkshopDialog(false)} color="primary">
                            Close
                        </Button>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="title"
                                label="Title"
                                fullWidth
                                value={workshop.title}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="duration"
                                label="Duration (minutes)"
                                type="number"
                                fullWidth
                                value={workshop.duration}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="host"
                                label="Host - this is the person who will be hosting your call"
                                fullWidth
                                value={workshop.host}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>Date & Time</Typography>
                            <input aria-label="Date & Time" type="datetime-local" name="date" value={formatDateTimeLocal(workshop.date)} onChange={handleDateChange} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="information"
                                label="Information"
                                fullWidth
                                value={workshop.information}
                                onChange={handleChange}
                                required
                                multiline
                                rows={6}
                                inputProps={{ maxLength: 2000 }}
                                helperText={`${workshop.information.length}/2000`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="autoRecord" checked={workshop.autoRecord} onChange={handleCheckboxChange} />} label="Automatically record meeting" />
                                <FormControlLabel control={<Checkbox name="sendRecording" checked={workshop.sendRecording} onChange={handleCheckboxChange} />} label="Automatically send recording to attendees" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {src ? (
                                <>
                                    <Typography variant="body2" sx={{ mb: 2 }}>Select desired area to crop your image.</Typography>
                                    <ImageCropper src={src} onCropComplete={handleCropComplete} aspect={1} />
                                </>

                            ) : (
                                <>
                                    <Typography variant="body2" sx={{ mb: 2 }}>Please upload an image with a 1:1 aspect ratio (e.g., 800x800 pixels) to ensure proper display. You can also crop it after uploading.</Typography>
                                    <FileUpload onDrop={handleUploadPhoto} fileType="image" fileLabel="cover" maxFiles={1} accept="image/*" />
                                </>
                            )}
                        </Grid>
                        {newCoverImage && <Grid item xs={12} sm={6}>
                            <ImageListItem>
                                <img src={newCoverImage.url} alt="cover" />
                            </ImageListItem>
                        </Grid>}
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="recurring" checked={workshop.recurring} onChange={handleCheckboxChange} />} label="Recurring meeting" />
                            </FormGroup>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                                <Typography variant="body2" sx={{ mr: 3 }}>Recurrence</Typography>
                                <Select value={workshop.recurrence} onChange={handleChange} name="recurrence" disabled={!workshop.recurring}>
                                    <MenuItem value="daily">Daily</MenuItem>
                                    <MenuItem value="weekly">Weekly</MenuItem>
                                    <MenuItem value="monthly">Monthly</MenuItem>
                                    <MenuItem value="yearly">Yearly</MenuItem>
                                </Select>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                                <Typography variant="body2" sx={{ mr: 3 }}>Repeat every</Typography>
                                <TextField type="number" value={workshop.repeatEvery} onChange={(e) => handleNumberChange(e, 1)} name="repeatEvery" sx={{ mr: 1 }} inputProps={{ min: 1 }} disabled={!workshop.recurring} />
                                <Typography variant="body2" sx={{}}>{workshop.recurrence === 'daily' ? 'day(s)' : workshop.recurrence === 'weekly' ? 'week(s)' : workshop.recurrence === 'monthly' ? 'month(s)' : 'year(s)'}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography variant="body2" sx={{ mr: 3 }}>End date</Typography>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox name="endDateType" checked={workshop.endDateType === 'none'} onChange={(e) => setWorkshop(prev => ({ ...prev, endDateType: e.target.checked ? 'none' : null }))} disabled={!workshop.recurring} />}
                                        label={<Typography variant="body2" sx={{}}>No end time</Typography>}
                                        sx={{ mr: 3 }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="endDateType" checked={workshop.endDateType === 'date'} onChange={(e) => setWorkshop(prev => ({ ...prev, endDateType: e.target.checked ? 'date' : null }))} disabled={!workshop.recurring} />}
                                        label={<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography variant="body2" sx={{ mr: 1 }}>By</Typography>
                                            <input
                                                aria-label="Date & Time"
                                                type="datetime-local"
                                                name="endDate"
                                                value={formatDateTimeLocal(workshop.endDate)}
                                                onChange={handleDateChange}
                                                min={formatDateTimeLocal(new Date(new Date(workshop.date).getTime() + 24 * 60 * 60 * 1000))}
                                                disabled={workshop.endDateType !== 'date' || !workshop.recurring}
                                            />
                                        </Box>}
                                        sx={{ mr: 3 }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="endDateType" checked={workshop.endDateType === 'occurrences'} onChange={(e) => setWorkshop(prev => ({ ...prev, endDateType: e.target.checked ? 'occurrences' : null }))} disabled={!workshop.recurring} />}
                                        label={<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography variant="body2" sx={{ mr: 1 }}>After</Typography>
                                            <TextField type="number" value={workshop.endDateOccurrences} onChange={(e) => handleNumberChange(e, 2, 100)} name="endDateOccurrences" inputProps={{ min: 2, max: 100 }} disabled={workshop.endDateType !== 'occurrences' || !workshop.recurring} />
                                            <Typography variant="body2" sx={{ ml: 1 }}>occurrences</Typography>
                                        </Box>}
                                    />
                                </FormGroup>
                            </Box>
                        </Grid>
                    </Grid>
                    <br />
                    {isSubmitting && <LinearProgress />}
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || isUploading}
                        type="submit"
                        sx={{ mb: 2 }}
                    >
                        Add Live Event
                    </Button>
                </form>
                <Button
                    variant="outlined"
                    onClick={handleRevokeZoom}
                    sx={{ mb: 5 }}
                >
                    Connect Another Zoom Account
                </Button>
            </>
        )
    );
};

export default WorkshopForm;
