import { createContext, useEffect, useState } from 'react';

const SupportContext = createContext({
    supportEmail: {},
    appName: {},
    getSupportEmail: () => { },
    getAppName: () => { },
    updateSupportEmail: () => { },
    updateAppName: () => { },
});

const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
    const accessToken = localStorage.getItem('accessToken')
    return accessToken
        ? {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
        : {
            'Content-Type': 'application/json',
        }
}


export const SupportProvider = (props) => {
    const { children } = props;
    const [supportEmail, setSupportEmail] = useState({});
    const [appName, setAppName] = useState({});

    const getSupportEmail = async () => {
        try {
            const response = await fetch(`${nodeURL}/support`, {
                method: 'GET',
                headers: headers()
            });
            const data = await response.json();
            setSupportEmail(data.email);
        } catch (error) {
            console.error(error);
        }
    };

    const getAppName = async () => {
        try {
            const response = await fetch(`${nodeURL}/support`, {
                method: 'GET',
                headers: headers()
            });
            const data = await response.json();
            setAppName(data.brand);
        } catch (error) {
            console.error(error);
        }
    };

    const updateSupportEmail = async (email) => {
        try {
            const response = await fetch(`${nodeURL}/support`, {
                method: 'PUT',
                headers: headers(),
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            setSupportEmail(data.email);
        } catch (error) {
            console.error(error);
        }
    }

    const updateAppName = async (brand) => {
        try {
            const response = await fetch(`${nodeURL}/support`, {
                method: 'PUT',
                headers: headers(),
                body: JSON.stringify({ brand }),
            });
            const data = await response.json();
            setAppName(data.brand);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getSupportEmail();
        getAppName();
    }, []);

    return (
        <SupportContext.Provider
            value={{
                supportEmail,
                getSupportEmail,
                updateSupportEmail,
                appName,
                getAppName,
                updateAppName,
            }}
        >
            {children}
        </SupportContext.Provider>
    );
};

export const SupportConsumer = SupportContext.Consumer;
export default SupportContext;
