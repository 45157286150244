import { useState, useEffect } from 'react'
import { fetchWorkshops } from "../../../actions/workshopActions";
import { Container, Typography, Box, Button, Dialog } from '@material-ui/core';
import WorkshopForm from './WorkshopForm';
import { WorkshopTable } from './WorkshopTable';

const today = new Date();
today.setSeconds(0, 0);

const WorkshopView = () => {
    const [scheduledWorkshops, setScheduledWorkshops] = useState([]);
    const [pastWorkshops, setPastWorkshops] = useState([]);
    const [showAddWorkshopDialog, setShowAddWorkshopDialog] = useState(false);
    const [scheduledWorkshopsLoading, setScheduledWorkshopsLoading] = useState(true);
    const [pastWorkshopsLoading, setPastWorkshopsLoading] = useState(true);
    const [upcomingPage, setUpcomingPage] = useState(0);
    const [upcomingRowsPerPage, setUpcomingRowsPerPage] = useState(5);
    const [pastPage, setPastPage] = useState(0);
    const [pastRowsPerPage, setPastRowsPerPage] = useState(5);
    const [totalScheduledWorkshops, setTotalScheduledWorkshops] = useState(0);
    const [totalPastWorkshops, setTotalPastWorkshops] = useState(0);

    const getPastWorkshops = async (skip = 0) => {
        if (!pastWorkshopsLoading) setPastWorkshopsLoading(true);

        try {
            const response = await fetchWorkshops({ filters: { date_lt: today.toISOString(), _start: skip, _limit: pastRowsPerPage, _sort: 'date:desc' } })
            if (response && response.length > 0) {
                setPastWorkshops(response)
                setPastWorkshopsLoading(false)
            }
        } catch (error) {
            setPastWorkshops([])
            setPastWorkshopsLoading(false)
        }
        getTotalPastWorkshops();
    }

    const getScheduledWorkshops = async (skip = 0) => {
        if (!scheduledWorkshopsLoading) setScheduledWorkshopsLoading(true);

        try {
            const response = await fetchWorkshops({ filters: { date_gte: today.toISOString(), _start: skip, _limit: upcomingRowsPerPage, _sort: 'date:asc' } })
            if (response && response.length > 0) {
                setScheduledWorkshops(response)
                setScheduledWorkshopsLoading(false)
            } else {
                setScheduledWorkshops([])
                setScheduledWorkshopsLoading(false)
            }
        } catch (error) {
            setScheduledWorkshops([])
            setScheduledWorkshopsLoading(false)
        }
        getTotalScheduledWorkshops();
    }

    const getTotalScheduledWorkshops = async () => {
        try {
            const response = await fetchWorkshops({ filters: { date_gte: today.toISOString() } })
            setTotalScheduledWorkshops(response.length)
        } catch (error) {
            setTotalScheduledWorkshops(0)
        }
    }

    const getTotalPastWorkshops = async () => {
        try {
            const response = await fetchWorkshops({ filters: { date_lt: today.toISOString() } })
            setTotalPastWorkshops(response.length)
        } catch (error) {
            setTotalPastWorkshops(0)
        }
    }

    useEffect(() => {
        getScheduledWorkshops(upcomingPage * upcomingRowsPerPage);
    }, [upcomingPage, upcomingRowsPerPage, showAddWorkshopDialog])

    useEffect(() => {
        getPastWorkshops(pastPage * pastRowsPerPage);
    }, [pastPage, pastRowsPerPage])

    return (
        <Container>
            <Box sx={{ my: 5 }}>
                <Button onClick={() => setShowAddWorkshopDialog(true)} variant="contained" color="primary">
                    Add Live Event
                </Button>
                <Dialog open={showAddWorkshopDialog} onClose={() => setShowAddWorkshopDialog(false)} maxWidth="lg">
                    <Box sx={{ px: 5, pb: 2 }}>
                        <WorkshopForm setShowAddWorkshopDialog={setShowAddWorkshopDialog} getWorkshops={getScheduledWorkshops} />
                    </Box>
                </Dialog>
            </Box>
            {scheduledWorkshops && scheduledWorkshops.length > 0 && (
                <Box sx={{ py: 3 }}>
                    <Typography variant="h4" sx={{ mb: 3 }}>
                        Upcoming Live
                    </Typography>
                    <WorkshopTable workshops={scheduledWorkshops} loading={scheduledWorkshopsLoading} page={upcomingPage} setPage={setUpcomingPage} rowsPerPage={upcomingRowsPerPage} setRowsPerPage={setUpcomingRowsPerPage} total={totalScheduledWorkshops} getWorkshops={getScheduledWorkshops} />
                </Box>
            )}
            {pastWorkshops && pastWorkshops.length > 0 && (
                <Box>
                    <Typography variant="h4" sx={{ mb: 3 }}>
                        Past Live
                    </Typography>
                    <WorkshopTable workshops={pastWorkshops} past={true} loading={pastWorkshopsLoading} page={pastPage} setPage={setPastPage} rowsPerPage={pastRowsPerPage} setRowsPerPage={setPastRowsPerPage} total={totalPastWorkshops} getWorkshops={getPastWorkshops} />
                </Box>
            )}
        </Container>
    )
}

export default WorkshopView