const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
		'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}

// *********** CREATE VIDEO (upload a new video) *********** //
export const createChannel = async (details) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify(details),
	}

	const response = await fetch(`${nodeURL}/channels`, opts).then((data) => data.json())

	return response
}

// ********** CREATE ATTACHMENT  ********** //
export const createAttachment = async (body) => {
	const opts = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
		body: body,
	}

	const response = await fetch(`${nodeURL}/upload`, opts).then((data) => data.json())
	return response
}

// *********** FETCH ALL CHANNELS *********** //
export const fetchChannels = async (userId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/channels/dashboard`, opts).then((data) => data.json())

	return response
}

// *********** FETCH A VIDEO (BY ID) *********** //
export const fetchChannel = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/channels/${id}`, opts).then((data) => data.json())

	return response
}

// *********** UPDATE A CHANNEL (BY ID) *********** //
export const updateChannel = async (id, details) => {
	const opts = {
		method: 'PUT',
		headers: headers(),
		body: JSON.stringify(details),
	}

	const response = await fetch(`${nodeURL}/channels/${id}`, opts).then((data) => data.json())

	return response
}

// *********** DELETE A CHANNEL (BY ID) *********** //
export const deleteChannel = async (id) => {
	const opts = {
		method: 'DELETE',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/channels/${id}`, opts).then((data) => data.json())

	return response
}

/**
 * 
 * @param {string} userId 
 * @returns {Promise<{ sortOrder: string[], filterSpecs: object }>}
 */
export const fetchChannelSortOrder = async (userId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/channels/sortorder/${userId}`, opts).then((data) => data.json())

	return response
}

export const fetchFilteredSortedChannels = async (publisherId, filterVals, abort) => {
	const opts = {
		method: 'GET',
		headers: headers(),
		signal: abort
	}
	const params = new URLSearchParams()
	if (filterVals) {
		Object.keys(filterVals).forEach(key => params.append(key, filterVals[key]))
	}
	const response = await fetch(`${nodeURL}/channels/bypublisher/${publisherId}?${params.toString()}`, opts)
	return await response.json()
}

export const fetchChannelsSansVideos = async (publisherId, abort) => {
	const opts = {
		method: 'GET',
		headers: headers(),
		signal: abort
	}

	const response = await fetch(`${nodeURL}/channels/bypublishersansvideos/${publisherId}`, opts)
	return await response.json()
}

export const fetchChannelWithDetails = async (channelId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}
	const response = await fetch(`${nodeURL}/channels/detailed/${channelId}`, opts).then((data) => data.json())
	return response
}
